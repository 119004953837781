import React, { useState, useEffect, useContext, useReducer } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import Header from "../header/Header";
import "./evaluations.css";
import RefineResults from "./RefineResults.js";
import EvaluationsCourseSearch from "./EvaluationsCourseSearch";
import OverviewModal from "./OverviewModal";
import Modal from "react-modal";

const GET_EVALUATION_CHART_BY_COURSE = gql`
    query getEvaluationChartByCourse($course: String!) {
        getEvaluationChartByCourse(course: $course) {
            courseName
            instructor
            term
            enrolled_amount
            organization {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            assignments {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            overall {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            challenge {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            workload {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            why_taking {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_grade {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_pf {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            comments {
                text
                time
            }
            difficulty
            mean_overall_difficulty
            Department
            times_taught
            normalized_course_difficulty
            normalized_department_difficulty
            median_difficulty
            quantile_25
            quantile_75
            weighted_overall_difference
            weighted_recency_difficulty
            normalized_instructors_difficulty
        }
    }
`;

const QUERY_GET_CLASS_DIFFICULTY = gql`
    query getClassDifficulty($short_name: String!) {
        getClassDifficulty(short_name: $short_name) {
            name
            overall_class_mean
            workload_class_mean
            expected_grade_class_mean
            difficulty4_plus
        }
    }
`;

function Evaluations() {
    const [overviewStateOpen, setOverviewStateOpen] = useState(false);
    // Obtain available professors and years for each course
    // const [showModalButton, setModalButton] = useState(false);
    const [years, setCourseYears] = useState([]);
    const [profs, setProfs] = useState([]);
    const [courseDifficultyInfo, setCourseDifficultyInfo] = useState({});
    const [courseName, setCourseName] = useState("");
    // console.log("available evals", available_evals);
    const [loadData, { loading: l, error: e, data: available_evals }] =
        useLazyQuery(GET_EVALUATION_CHART_BY_COURSE, {
            variables: {
                course: courseName,
            },
        });

    const [
        loadDifficultyData,
        { loading: ld, error: ed, data: difficulty_data },
    ] = useLazyQuery(QUERY_GET_CLASS_DIFFICULTY, {
        variables: {
            short_name: courseName,
        },
    });

    useEffect(() => {
        if (courseName) {
            // console.log(courseName);
            loadData();
            loadDifficultyData();
        }
    }, [courseName]);
    // console.log('our data', available_evals.getEvaluationChartByCourse);

    useEffect(() => {
        if (difficulty_data) {
            let diff =
                difficulty_data?.getClassDifficulty?.difficulty4_plus ?? 0;
            let temp = difficulty_data?.getClassDifficulty?.name;
            let name = courseName + temp?.split("-")[1];

            let expected_grade =
                difficulty_data?.getClassDifficulty
                    ?.expected_grade_class_mean ?? 0;
            let overall_class_mean =
                difficulty_data?.getClassDifficulty?.overall_class_mean ?? 0;
            let workload_class_mean =
                difficulty_data?.getClassDifficulty?.workload_class_mean ?? 0;

            setCourseDifficultyInfo({
                diff,
                name,
                expected_grade,
                overall_class_mean,
                workload_class_mean,
            });
        }
    }, [difficulty_data, ld]);

    const openOverviewModal = () => {
        setOverviewStateOpen(true);
    };

    const closeOverviewModal = () => {
        setOverviewStateOpen(false);
    };

    return (
        <div className="container">
            {/* {courseName && courseInfo ? (
                <div>
                    <button onClick={openOverviewModal}>Open Modal</button>
                    <Modal
                        isOpen={overviewStateOpen}
                        onRequestClose={closeOverviewModal}
                        className="overviewModal"
                    >
                        <OverviewModal
                            closeOverviewModal={closeOverviewModal}
                            courseInfo={courseInfo}
                        />
                    </Modal>
                </div>
            ) : null} */}

            <Header />
            <div className="EvalPageContainer">
                <EvaluationsCourseSearch setCurrCourseName={setCourseName} />
                {available_evals && (
                    <RefineResults
                        available_evals={available_evals}
                        overviewStateOpen={overviewStateOpen}
                        setOverviewStateOpen={setOverviewStateOpen}
                        courseDifficultyInfo={courseDifficultyInfo}
                    />
                )}
            </div>
        </div>
    );
}

export default Evaluations;
