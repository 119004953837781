import React, { useState, useEffect, useContext } from "react";
import Selection from "../search/Selection";
import { initGA } from "../../utils/analytics";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import { customStylesEval } from "../search/SelectStyles";
import { ThemeProvider } from "@mui/styles";
import TextField from "@mui/material/TextField";
import "../search/CourseSearch.global.css";
import "./evaluations.css";
import CompiledLists from "../search/CompiledLists";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Context as CourseSearchContext } from "../../contexts/courseSearchContext";
import useWindowDimensions from "../useWindowDimensions";
import CourseEvalContent from "./CourseEvalContent";
import Modal from "react-modal";
import OverviewModal from "./OverviewModal";

const GET_LOCAL_DATA = gql`
    query GetLocalData {
        courseEvalsList @client
    }
`;

const GET_EVALUATION_CHART_BY_COURSE = gql`
    query getEvaluationChartByCourse($course: String!) {
        getEvaluationChartByCourse(course: $course) {
            courseName
            name
            term
            termValue
            enrolled_amount
            organization {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            assignments {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            overall {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            challenge {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            workload {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            why_taking {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_grade {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_pf {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            comments {
                text
                time
            }
            difficulty
            mean_overall_difficulty
            Department
            times_taught
            normalized_course_difficulty
            normalized_department_difficulty
            median_difficulty
            quantile_25
            quantile_75
            weighted_overall_difference
            weighted_recency_difficulty
            normalized_instructors_difficulty
        }
    }
`;

const GET_EVALUATION_CHART_BY_CRN_AND_TERM = gql`
    query getEvaluationChartByCRNAndTerm($crn: Int, $termValue: Int) {
        getEvaluationChartByCRNAndTerm(crn: $crn, termValue: $termValue) {
            courseName
            crn
            name
            term
            termValue
            enrolled_amount
            organization {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            assignments {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            overall {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            challenge {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            workload {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            why_taking {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_grade {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            expected_pf {
                class_mean
                responses
                score_1
                score_2
                score_3
                score_4
                score_5
            }
            comments {
                text
                time
            }
            difficulty
            mean_overall_difficulty
            Department
            times_taught
            normalized_course_difficulty
            normalized_department_difficulty
            median_difficulty
            quantile_25
            quantile_75
            weighted_overall_difference
            weighted_recency_difficulty
            normalized_instructors_difficulty
        }
    }
`;

const RefineResults = ({
    available_evals,
    overviewStateOpen,
    setOverviewStateOpen,
    courseDifficultyInfo,
}) => {
    const [activeSemesterButtonIndex, setSemesterButtonIndex] = useState(0);
    const [activeProfessorButtonIndex, setProfessorButtonIndex] = useState(0);
    let { data: storeData } = useQuery(GET_LOCAL_DATA);
    let { courseEvalsList } = storeData;

    //two state variables, on is semests, one is professors
    const [semesters, setSemesters] = useState([]);
    const [profs, setProfs] = useState([]);

    useEffect(() => {
        // 1. from availableEvals, get all possible semesters, make a list
        let semesters = Array.from(
            new Set(
                available_evals.getEvaluationChartByCourse.map(
                    (course) => course.term
                )
            )
        ).map((p, idx) => {
            return {
                label: p,
                value: idx,
            };
        });
        setSemesters(semesters);
        // 2. from the most recent semester, then filter available evals by that semester, get the professors, and set prof list to this
        let profs = Array.from(
            new Set(
                available_evals.getEvaluationChartByCourse
                    .filter(
                        (course) => course["term"] === semesters[0]["label"]
                    )
                    .map((course) => course.instructor)
            )
        ).map((p, idx) => {
            return {
                label: p,
                value: idx,
            };
        });
        setProfs(profs);
    }, [available_evals]);

    const handleSemesterSearchChange = (newFilter) => {
        setSemesterButtonIndex(newFilter.value);
        const newProfs = Array.from(
            new Set(
                available_evals.getEvaluationChartByCourse
                    .filter(
                        (course) =>
                            course["term"] ===
                            semesters[parseInt(newFilter.value)]["label"]
                    )
                    .map((course) => course.instructor)
            )
        ).map((p, idx) => {
            return {
                label: p,
                value: idx,
            };
        });
        setProfs(newProfs);
        setProfessorButtonIndex(0);
    };
    const handleProfessorSearchChange = (newFilter) => {
        setProfessorButtonIndex(newFilter.value);
    };

    const openOverviewModal = () => {
        setOverviewStateOpen(true);
    };

    const closeOverviewModal = () => {
        setOverviewStateOpen(false);
    };

    return (
        <div className="refineResultsContainer">
            <div className="refineresultsTEXT">
                <div className="searchTextEvals"> Refine Results: </div>
            </div>
            <div className="SearchBoxesContainer">
                <div
                    style={{
                        marginRight: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <div>
                        <div className="eval-refine-result-select">
                            <Select
                                // className="react-select-container"
                                value={semesters[activeSemesterButtonIndex]}
                                onChange={handleSemesterSearchChange}
                                options={semesters}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        text: "white",
                                    },
                                })}
                                styles={customStylesEval}
                            />
                        </div>
                    </div>
                    <div className="eval-refine-result-select">
                        <Select
                            // className="react-select-container"
                            value={profs[activeProfessorButtonIndex]}
                            onChange={handleProfessorSearchChange}
                            options={profs}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    text: "white",
                                },
                            })}
                            styles={customStylesEval}
                        />
                        {/* <div className="filter2">{displaySearch()}</div> */}
                    </div>
                </div>
                <Modal
                    isOpen={overviewStateOpen}
                    onRequestClose={closeOverviewModal}
                    className="overviewModal"
                >
                    <OverviewModal
                        closeOverviewModal={closeOverviewModal}
                        courseDifficultyInfo={courseDifficultyInfo}
                    />
                </Modal>
            </div>
            <div className="ListEvalsContainer">
                {/* <button onClick={openOverviewModal}>Open Modal</button> */}
                {/*    {courseEvalsList &&
                        courseEvalsList.map((course) => {
                            let temp = course.courseName.split(" ");
                            let subject = temp[0];
                            let courseNum = temp[1];
                            return (
                                <CourseEvalContent
                                    query={GET_EVALUATION_CHART_BY_COURSE}
                                    courseSubject={subject}
                                    courseNum={courseNum}
                                    courseTitle={course.name}
                                    courseProf={course.instructor}
                                    creditsMin={3}
                                    closeModal={() => {}}
                                    evalPage={true}
                                />
                            );
                        })} */}
                {courseEvalsList.length > 0 ? (
                    courseEvalsList
                        .filter(
                            (item, index) =>
                                item.instructor ==
                                    profs?.[activeProfessorButtonIndex]
                                        ?.label &&
                                item.term ==
                                    semesters?.[activeSemesterButtonIndex]
                                        ?.label
                        )
                        .map((course) => {
                            let temp = course.courseName.split(" ");
                            let subject = temp[0];
                            let courseNum = temp[1];
                            let crn = course.crn;
                            let termValue = course.termValue;
                            return (
                                <CourseEvalContent
                                    isEvaluationPage={true}
                                    query={GET_EVALUATION_CHART_BY_CRN_AND_TERM}
                                    courseSubject={subject}
                                    courseNum={courseNum}
                                    courseTitle={course.name}
                                    courseProf={course.instructor}
                                    creditsMin={3}
                                    closeModal={() => {}}
                                    crn={crn}
                                    termValue={termValue}
                                    openOverviewModal={openOverviewModal}
                                    evalPage={true}
                                    evalData={course}
                                />
                            );
                        })
                ) : (
                    <h2>
                        There are currently no evaluations for this course.
                        Please select another course!
                    </h2>
                )}
            </div>
        </div>
    );
};
export default RefineResults;
