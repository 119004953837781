import react, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

const chartKeys = [
    {
        args: ["A", "B", "C", "D", "F"],
        title: "Expected Grade (Letter)",
        key: "expected_grade",
        riceMean: 1.33,
    },
    {
        args: ["P", "F", "S", "U", "N/A"],
        title: "Expected Grade (P/F)",
        key: "expected_pf",
        riceMean: 1.44,
    },
    {
        args: ["Outstanding", "Good", "Average", "Fair", "Poor"],
        title: "The organization of this course was:",
        key: "organization",
        riceMean: 1.7,
    },
    {
        args: ["Outstanding", "Good", "Average", "Fair", "Poor"],
        title: "The contribution that the coursework made to the course was:",
        key: "assignments",
        riceMean: 1.7,
    },
    {
        args: ["Outstanding", "Good", "Average", "Fair", "Poor"],
        title: "Overall, I would rate the quality of this course as:",
        key: "overall",
        riceMean: 1.71,
    },
    {
        args: [
            "Strongly Agree",
            "Agree",
            "Neutral",
            "Disagree",
            "Strongly Disagree",
        ],
        title: "I was challenged to extend my capabilities or to develop new ones:",
        key: "challenge",
        riceMean: 1.69,
    },
    {
        args: [
            "Much Lighter",
            "Somewhat Lighter",
            "Average",
            "Somewhat Heavier",
            "Much Heavier",
        ],
        title: "The workload of this course compared to others was:",
        key: "workload",
        riceMean: 2.84,
    },
    {
        args: [
            "Univ or Dist Req",
            "Req for Major",
            "Elective in Major",
            "Free Elective",
            "N/A",
        ],
        title: "I am taking this course because it satisfies:",
        key: "why_taking",
        riceMean: 2.29,
    },
];

export const FetchEvalData = (
    isEvaluationPage,
    thisCourse,
    crn,
    termValue,
    query,
    setComments,
    setTerm,
    setCompiledEvalData
) => {
    const [evalDataState, setEvalDataState] = useState([]);
    const {
        loading: evalLoading,
        error: evalError,
        data: evalData,
    } = useQuery(query, {
        variables: isEvaluationPage
            ? { crn: crn, termValue: termValue }
            : { course: thisCourse },
    });

    useEffect(() => {
        setEvalDataState(
            isEvaluationPage
                ? evalData?.getEvaluationChartByCRNAndTerm[0]
                : evalData?.getEvaluationChartByCourse[0]
        );
    }, [evalLoading, evalError, evalData]);

    useEffect(() => {
        if (evalDataState) {
            let allEvalData = [];
            //Get term
            let term = evalDataState.term ? evalDataState.term : "";
            setTerm(term);

            chartKeys.map((item, index) => {
                let curData = evalDataState[item.key];
                let responses = verifyNum(curData, "responses");
                let classMean = verifyNum(curData, "class_mean");
                let chartValues = getChartValues(item.args, curData);

                allEvalData.push({
                    data: chartValues,
                    responses: responses,
                    classMean: classMean,
                    riceMean: item.riceMean,
                    title: item.title,
                });
            });

            //Get class comments
            let curComments = evalDataState.comments;
            let commentsArr = curComments ? curComments : [];

            setComments(commentsArr);
            setCompiledEvalData(allEvalData);
        }
    }, [evalDataState, evalData]);
};

export const FormatEvalData = (
    evalData,
    setComments,
    setTerm,
    setCompiledEvalData
) => {
    let allEvalData = [];
    //Get term
    let term = evalData.term ? evalData.term : "";
    setTerm(term);

    chartKeys.map((item, index) => {
        let curData = evalData[item.key];
        let responses = verifyNum(curData, "responses");
        let classMean = verifyNum(curData, "class_mean");
        let chartValues = getChartValues(item.args, curData);

        allEvalData.push({
            data: chartValues,
            responses: responses,
            classMean: classMean,
            riceMean: item.riceMean,
            title: item.title,
        });
    });

    //Get class comments
    let curComments = evalData.comments;
    let commentsArr = curComments ? curComments : [];

    setComments(commentsArr);
    setCompiledEvalData(allEvalData);
};

const verifyNum = (data, key) => {
    return data ? parseFloat(data[key]) : 0;
};

const getChartValues = (args, data) => {
    const scores = ["score_1", "score_2", "score_3", "score_4", "score_5"];
    return args.map((arg, index) => {
        return {
            argument: arg,
            value: verifyNum(data, scores[index]),
        };
    });
};
