import React, { useEffect, useState } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineWarning } from "react-icons/ai";
import "./CourseEval.css";
import { FetchEvalData, FormatEvalData } from "./FetchEvalData";

import Chart, {
    Margin,
    ArgumentAxis,
    ValueAxis,
    Series,
    Legend,
    Size,
    Title,
    Font,
    Label,
    Annotation,
    CommonAnnotationSettings,
    Border,
    Shadow,
    Format,
} from "devextreme-react/chart";

const CourseEvalContent = (props) => {
    const [comments, setComments] = useState([]);
    const [term, setTerm] = useState("");
    const [compiledEvalData, setCompiledEvalData] = useState([]);
    const [chartTabState, setChartTabState] = useState(true);

    const client = useApolloClient();
    const thisCourse = props.courseSubject + " " + props.courseNum;

    const openCommentTab = () => {
        setChartTabState(false);
    };
    const openChartTab = () => {
        setChartTabState(true);
    };
    //logic for warning message. to ensure that it only displays once and not each time the tab is opened
    const GET_LOCAL_DATA = gql`
        query GetLocalData {
            warningState @client
        }
    `;
    const { data: localQueryData } = useQuery(GET_LOCAL_DATA);
    let { warningState } = localQueryData;

    const closeWarning = () => {
        client.writeQuery({
            query: GET_LOCAL_DATA,
            data: { warningState: false },
        });
        warningState = false;
    };
    //Fetch and format all Evaluation data

    // useEffect(()=> {
    //     if (props.evalPage){
    //         console.log(props.evalData)
    //         FormatEvalData(
    //             props.evalData,
    //             setComments,
    //             setTerm,
    //             setCompiledEvalData
    //         )
    //     }
    // }, [props.evalData])

    // useEffect(()=> {
    //     if (!props.evalPage){
    //         FetchEvalData (
    //         thisCourse,
    //         props.query,
    //         setComments,
    //         setTerm,
    //         setCompiledEvalData)
    //     }
    // }, [props.query])

    FetchEvalData(
        props.isEvaluationPage,
        thisCourse,
        props.crn,
        props.termValue,
        props.query,
        setComments,
        setTerm,
        setCompiledEvalData
    );

    return (
        <div className="modal-container">
            {warningState ? (
                <WarningMessage closeWarning={closeWarning} />
            ) : props.evalPage ? (
                <div></div>
            ) : (
                <div>
                    <button
                        className="exit exit-evals"
                        onClick={props.closeModal}
                    >
                        <IoCloseOutline />
                    </button>
                </div>
            )}
            <div className="course-info">
                <CourseHeader
                    isEvaluationPage={props.isEvaluationPage}
                    thisCourse={thisCourse}
                    courseTitle={props.courseTitle}
                    term={term}
                    courseProf={props.courseProf}
                    evalPage={props.evalPage}
                />
                <div className="evalTabsContainer">
                    <div className="eval-tabs">
                        <button
                            className={`${
                                chartTabState
                                    ? "eval-tabs-clicked"
                                    : "eval-tabs-unclicked"
                            }`}
                            onClick={openChartTab}
                        >
                            Numeric Responses
                        </button>
                        <button
                            className={`${
                                !chartTabState
                                    ? "eval-tabs-clicked"
                                    : "eval-tabs-unclicked"
                            }`}
                            onClick={openCommentTab}
                        >
                            Student Reviews
                        </button>
                    </div>
                    {props.isEvaluationPage && (
                        <div className="evalTabsContainerRight">
                            <button
                                onClick={props.openOverviewModal}
                                className="overviewButton"
                            >
                                Overview
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <TabContent
                chartTabState={chartTabState}
                compiledEvalData={compiledEvalData}
                comments={comments}
                evalPage={props.evalPage}
            />
        </div>
    );
};
export default CourseEvalContent;

const WarningMessage = ({ closeWarning }) => {
    return (
        <div className="eval-warning">
            <div className="warning-icon">
                <AiOutlineWarning color="red" size={40} />
            </div>
            <p className="warning-text">
                These evaluations are intended to be available only to Rice
                students, faculty and staff on its internal computer network.
                This information is considered confidential and is to be used
                solely by, within and amongst the Rice University community and
                its members.
            </p>
            <div>
                <button className="exit" onClick={closeWarning}>
                    <IoCloseOutline />
                </button>
            </div>
        </div>
    );
};

const CourseHeader = ({
    isEvaluationPage,
    thisCourse,
    courseTitle,
    term,
    courseProf,
    evalPage,
}) => {
    const fontSize = {
        courseTitle: evalPage ? "24px" : "32px",
        courseDetails: evalPage ? "16px" : "20px",
    };

    const renderInstructorByEvaluationPage = (isEvaluationPage) => {
        if (!isEvaluationPage) {
            return (
                courseProf && courseProf.firstName + " " + courseProf.lastName
            );
        } else {
            return courseProf ? courseProf : "No Instructors";
        }
    };
    return (
        <div>
            <div className="course-eval-header">
                <h1 className="no-padding-no-margin">
                    {!isEvaluationPage && (
                        <span
                            className="course-num"
                            style={{ fontSize: fontSize.courseTitle }}
                        >
                            {thisCourse}
                        </span>
                    )}
                    <span
                        className="course-title-eval"
                        style={{ fontSize: fontSize.courseTitle }}
                    >
                        &nbsp;{courseTitle}
                    </span>
                </h1>
            </div>
            <p
                className="course-details"
                style={{ fontSize: fontSize.courseDetails }}
            >
                Term: {term}
            </p>
            <p
                className="course-details"
                tyle={{ fontSize: fontSize.courseDetails }}
            >
                Instructor: {renderInstructorByEvaluationPage(isEvaluationPage)}
            </p>
        </div>
    );
};

const TabContent = ({
    chartTabState,
    comments,
    compiledEvalData,
    evalPage,
}) => {
    if (chartTabState) {
        return (
            <div className="chart-padding">
                <div className="header-border" />
                <div className="charts-display">
                    <DisplayCharts
                        compiledEvalData={compiledEvalData}
                        evalPage={evalPage}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {comments.map((item, index) => {
                    return (
                        <div
                            className={`comment-container ${
                                index % 2 == 0 ? "comment-even" : ""
                            }`}
                            key={index}
                        >
                            <div>{item.text}</div>
                            <div className="time-stamp">{item.time}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
};

const DisplayCharts = ({ compiledEvalData, evalPage }) => {
    const chartStyles = {
        height: evalPage ? 220 : 270,
        boxWidth: evalPage ? "320px" : "400px",
        chartWidth: evalPage ? 310 : 360,
    };

    const formatChartPercent = (arg) => {
        return `${arg.valueText}%`;
    };

    return compiledEvalData.map((item) => {
        return (
            <div
                className="chart-container"
                style={{ width: chartStyles.boxWidth }}
            >
                <Title text={item.title}>
                    <Font
                        size="15px"
                        color={"var(--secondary-color)"}
                        family={"Acari Sans"}
                    />
                </Title>
                <p className="chart-title">{item.title}</p>
                <Chart dataSource={item.data} height={chartStyles.height}>
                    <Margin top={20}></Margin>
                    <CommonAnnotationSettings
                        type="text"
                        series="Value"
                        allowDragging={false}
                        color={"transparent"}
                    ></CommonAnnotationSettings>
                    {item.data
                        .filter((vals) => {
                            return vals.value > 0;
                        })
                        .map((vals) => (
                            <Annotation
                                argument={vals.argument}
                                key={vals.argument}
                                text={vals.value}
                                color={"transparent"}
                                arrowLength={0}
                                offsetY={-20}
                            >
                                <Font
                                    size="17px"
                                    color={"var(--secondary-color)"}
                                    family={"Acari Sans"}
                                />
                                <Border visible={false} />
                                <Shadow opacity={0} />
                            </Annotation>
                        ))}
                    <Series
                        type="bar"
                        hoverMode="none"
                        valueField="value"
                        argumentField="argument"
                        color={"var(--primary-color)"}
                        name="Value"
                    />

                    <ArgumentAxis>
                        <Label>
                            <Font
                                size="12px"
                                color={"var(--secondary-color)"}
                                family={"Acari Sans"}
                            />
                        </Label>
                    </ArgumentAxis>

                    <ValueAxis visualRange={[0, 100]} visible={false}>
                        <Label customizeText={formatChartPercent}>
                            <Font
                                size="15px"
                                color={"var(--secondary-color)"}
                                family={"Acari Sans"}
                            />
                        </Label>
                    </ValueAxis>
                    <Legend visible={false} />
                    <Size
                        height={chartStyles.height}
                        width={chartStyles.chartWidth}
                    />
                </Chart>
                <div className="chart-numbers-container">
                    <p className="chart-txt">
                        &nbsp;&nbsp;Responses:&nbsp;{item.responses}
                    </p>
                </div>
            </div>
        );
    });
};
