import React from "react";
import VisibilitySensor from "react-visibility-sensor/";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { buildStyles } from "react-circular-progressbar";
import { style } from "@mui/system";

function convertToGrade(expected_grade) {
    const mid = (4 - 3.66) / 2;
    let grade = "";
    //let temp_grade = 5-((expected_grade-1)*(4/3))
    const lerp = (v, a, b, x, y) => x + ((y - x) * (v - a)) / (b - a);
    let temp_grade = 4 - lerp(expected_grade, 1, 4, 0, 4);
    if (temp_grade > 3.66 + mid) {
        grade = "A";
    } else if (temp_grade > 3.33 + mid) {
        grade = "A-";
    } else if (temp_grade > 3.0 + mid) {
        grade = "B+";
    } else if (temp_grade > 2.66 + mid) {
        grade = "B";
    } else if (temp_grade > 2.33 + mid) {
        grade = "B-";
    } else if (temp_grade > 2.0 + mid) {
        grade = "C+";
    } else if (temp_grade > 1.66 + mid) {
        grade = "C";
    } else if (temp_grade > 1.33 + mid) {
        grade = "C-";
    } else if (temp_grade > 1.0 + mid) {
        grade = "D+";
    } else if (temp_grade > 0.66 + mid) {
        grade = "D";
    } else if (temp_grade > 0.33 + mid) {
        grade = "D-";
    } else {
        grade = "F";
    }
    return grade;
}

function OverviewModal({ courseDifficultyInfo }) {
    const {
        diff,
        name,
        expected_grade,
        overall_class_mean,
        workload_class_mean,
    } = courseDifficultyInfo;
    const grade = convertToGrade(expected_grade);
    //console.log('bruhgrekhjger', convertToGrade(1.21)) To calculate new total average, uncomment and replace 1.21 with average grade from python script

    return (
        <div>
            <p style={{ margin: "30px", width: "450px", fontSize: "30px" }}>
                {name}
            </p>{" "}
            {/* Need to remove the number thing */}
            <div style={{ width: "70px", height: "100%" }}>
                <div style={{ top: "20px" }} class="content">
                    <div
                        style={{
                            position: "relative",
                            left: "330px",
                            bottom: "10px",
                        }}
                    >
                        <p> </p>
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                const value = isVisible
                                    ? overall_class_mean
                                    : 0;
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        maxValue={5}
                                        text={`${value.toFixed(2)}`}
                                        styles={buildStyles({
                                            pathColor: "turquoise",
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                    </div>

                    <div
                        style={{
                            width: "70px",
                            position: "relative",
                            left: "330px",
                            bottom: "-5px",
                        }}
                        class="CircularProgressbar-trail"
                    >
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                //console.log(typeof(workload_class_mean))
                                //console.log(workload_class_mean.toFixed(2))
                                const value = isVisible ? diff * 0.025 : 0; // Divides by 200 max and multiply by 5
                                console.log(value);
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        maxValue={5}
                                        text={`${value.toFixed(2)}`}
                                        //text={`${value}`}

                                        styles={buildStyles({
                                            pathColor: "darkblue",
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                    </div>

                    <div
                        style={{
                            width: "70px",
                            position: "relative",
                            left: "330px",
                            top: "20px",
                        }}
                        class="CircularProgressbar-trail"
                    >
                        <VisibilitySensor>
                            {({ isVisible }) => {
                                const value = isVisible
                                    ? workload_class_mean
                                    : 0;
                                return (
                                    <CircularProgressbar
                                        value={value}
                                        maxValue={5}
                                        // text={`${value.toFixed(2)}`}
                                        text={`${value}`}
                                        styles={buildStyles({
                                            pathColor: "darkblue",
                                        })}
                                    />
                                );
                            }}
                        </VisibilitySensor>
                    </div>
                </div>

                <div>
                    <p
                        style={{
                            position: "absolute",
                            left: "420px",
                            bottom: "265px",
                        }}
                    >
                        Course Quality
                    </p>
                    <p
                        style={{
                            position: "absolute",
                            left: "420px",
                            bottom: "180px",
                        }}
                    >
                        Course Difficulty
                    </p>
                    <p
                        style={{
                            position: "absolute",
                            left: "420px",
                            bottom: "90px",
                        }}
                    >
                        Work Required
                    </p>
                </div>

                <div
                    style={{
                        width: "120px",
                        position: "fixed",
                        left: "80px",
                        bottom: "150px",
                    }}
                    class="CircularProgressbar-trail"
                >
                    <VisibilitySensor>
                        {({ isVisible }) => {
                            const value = grade;
                            return (
                                <CircularProgressbar
                                    value={0}
                                    text={`${value}`}
                                    styles={buildStyles({
                                        textSize: "45px",
                                    })}
                                />
                            );
                        }}
                    </VisibilitySensor>
                </div>

                <div>
                    <p
                        style={{
                            position: "fixed",
                            left: "80px",
                            bottom: "80px",
                            width: "120px",
                            textAlign: "center",
                        }}
                    >
                        Average Predicted Grade
                    </p>
                </div>
            </div>
            <div style={styles.main}>
                <div>
                    <p style={styles.title}> Department Average Stats:</p>
                </div>

                <div style={styles.stats}>
                    <div style={{ marginBottom: "10px", marginLeft: "10px" }}>
                        <p>Expected Grade: A-</p>
                        <p>Course Quality: 2.22</p>
                    </div>
                    <div style={{ marginBottom: "10px", marginLeft: "10px" }}>
                        <p>Course Difficulty: 1.56</p>
                        <p>Work Required: 2.86</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OverviewModal;

const styles = {
    average_stats_container: {},
    title: {
        color: "var(--secondary-color)",
        fontSize: "18px",
    },
    childrenStyle: {},
    stats: {
        display: "flex",
        flexDirection: "row",
        marginTop: "-22px",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "left",
        marginTop: "60px",
    },
};
